import { n as normalizeComponent } from "./index.js";
import { MAINTAIN_PERMISSION, FAS_CONFIGURATION } from "@kraftheinz/common";
var render$3 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("simple-form", {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function(_ref) {
        var handleSubmit = _ref.handleSubmit;
        return [_c("a-modal", {
          staticClass: "import-modal",
          attrs: {
            "destroy-on-close": "",
            "title": "Import",
            "visible": _vm.visible
          },
          on: {
            "cancel": _vm.cancel
          },
          scopedSlots: _vm._u([{
            key: "footer",
            fn: function() {
              return [_c("a-button", {
                key: "submit",
                attrs: {
                  "disabled": !_vm.file,
                  "type": "primary",
                  "loading": _vm.loading
                },
                on: {
                  "click": function($event) {
                    return handleSubmit(_vm.onImportClick);
                  }
                }
              }, [_vm._v(" Import ")])];
            },
            proxy: true
          }], null, true)
        }, [_c("a-row", {
          staticClass: "mb-3",
          attrs: {
            "gutter": 16
          }
        }, [_c("a-col", {
          attrs: {
            "span": 12
          }
        }, [_c("select-input", {
          staticClass: "country-select-filter",
          attrs: {
            "form-item": "",
            "label": "Country",
            "rules": "required",
            "data-source": _vm.countryList,
            "source": "entityLevelCode",
            "source-label": "entityLevelName",
            "default-active-first-option": "",
            "disabled": _vm.loading
          },
          on: {
            "change": _vm.onCountryChange
          }
        })], 1), _c("a-col", {
          attrs: {
            "span": 12
          }
        }, [_c("select-input", {
          attrs: {
            "form-item": "",
            "label": "Financial Year",
            "reference": "fas.financials",
            "source": "year",
            "disabled": _vm.loading,
            "rules": "required",
            "default-active-first-option": ""
          },
          on: {
            "change": _vm.onYearChange
          }
        })], 1)], 1), !_vm.file ? _c("a-upload-dragger", {
          staticClass: "mb-2",
          attrs: {
            "name": "file",
            "multiple": false,
            "action": _vm.customAction,
            "accept": ".xlsx,.csv"
          },
          on: {
            "change": _vm.onUploadFile
          }
        }, [_c("p", {
          staticClass: "ant-upload-drag-icon"
        }, [_c("a-icon", {
          attrs: {
            "type": "inbox"
          }
        })], 1), _c("p", {
          staticClass: "ant-upload-text"
        }, [_vm._v(" Click or drag file to this area to import ")]), _c("p", {
          staticClass: "ant-upload-hint"
        }, [_vm._v(" Support for a single or bulk upload. Strictly prohibit from importing company data or other band files ")])]) : _vm._e(), _vm.file ? _c("div", [_c("div", {
          staticClass: "files d-flex justify-content-between"
        }, [_c("div", [_c("a-icon", {
          staticClass: "mr-2",
          attrs: {
            "type": "file-excel"
          }
        }), _c("a", [_vm._v(_vm._s(_vm.file.name))])], 1), !_vm.loading ? _c("a-icon", {
          attrs: {
            "type": "close"
          },
          on: {
            "click": function() {
              return _vm.file = void 0;
            }
          }
        }) : _vm._e()], 1)]) : _vm._e()], 1)];
      }
    }])
  });
};
var staticRenderFns$3 = [];
var ImportModal_vue_vue_type_style_index_0_lang = "";
const apiUrl$2 = "#{VUE_APP_API_URL}#";
const __vue2_script$3 = {
  name: "ImportModal",
  inject: ["crud", "apiUrl"],
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      apiUrl: apiUrl$2,
      loading: false,
      file: void 0,
      filePath: void 0,
      countryList: [],
      country: "",
      year: "",
      customAction: `${apiUrl$2}/files/documents`
    };
  },
  watch: {
    visible(newVal) {
      if (!newVal)
        return;
      this.visible = newVal;
    }
  },
  async mounted() {
    await this.getListCountry();
  },
  methods: {
    cancel() {
      if (!this.loading) {
        this.$emit("update:visible", false);
        this.file = void 0;
      } else {
        this.$notification.warning({
          message: "Import file is being processed!"
        });
      }
    },
    async getListCountry() {
      const { data } = await this.axios.get(`${this.apiUrl}/identities/applications/contexts?app=fas&entityLevel=country`);
      this.countryList = data;
    },
    onUploadFile(info) {
      const { file } = info;
      if (file.status == "done") {
        this.filePath = file.response.path;
        this.file = file;
      }
    },
    onImportClick() {
      const formData = new FormData();
      formData.append("path", this.filePath);
      formData.append("countryCode", this.country);
      formData.append("year", this.year);
      this.loading = true;
      this.axios.post(`${this.apiUrl}/fas/imports/Upload-detail`, formData).then(() => {
        this.loading = false;
        this.crud.fetchList();
        this.cancel();
        this.$notification.success({ message: "Import Successful!" });
      }).catch((err) => {
        this.loading = false;
        console.log(err);
      });
    },
    onCountryChange(value) {
      this.country = value;
    },
    onYearChange(value) {
      this.year = value;
    }
  }
};
const __cssModules$3 = {};
var __component__$3 = /* @__PURE__ */ normalizeComponent(__vue2_script$3, render$3, staticRenderFns$3, false, __vue2_injectStyles$3, null, null, null);
function __vue2_injectStyles$3(context) {
  for (let o in __cssModules$3) {
    this[o] = __cssModules$3[o];
  }
}
var ImportModal$1 = /* @__PURE__ */ function() {
  return __component__$3.exports;
}();
var render$2 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("resource", {
    attrs: {
      "name": "fas.financials",
      "api-url": _vm.apiUrl
    }
  }), _c("resource", {
    attrs: {
      "name": "fas.filestorages",
      "api-url": _vm.apiUrl
    }
  }, [_c("import-modal", {
    attrs: {
      "visible": _vm.importModalVisible
    },
    on: {
      "update:visible": function($event) {
        _vm.importModalVisible = $event;
      }
    }
  })], 1)], 1);
};
var staticRenderFns$2 = [];
const apiUrl$1 = "#{VUE_APP_API_URL}#";
const __vue2_script$2 = {
  components: {
    ImportModal: ImportModal$1
  },
  props: {
    visible: {
      type: Boolean,
      defaul: false
    }
  },
  data() {
    return {
      apiUrl: apiUrl$1,
      importModalVisible: this.visible
    };
  },
  watch: {
    visible(newVal) {
      this.importModalVisible = newVal;
    },
    importModalVisible(newVal) {
      this.$emit("update:visible", newVal);
    }
  }
};
const __cssModules$2 = {};
var __component__$2 = /* @__PURE__ */ normalizeComponent(__vue2_script$2, render$2, staticRenderFns$2, false, __vue2_injectStyles$2, null, null, null);
function __vue2_injectStyles$2(context) {
  for (let o in __cssModules$2) {
    this[o] = __cssModules$2[o];
  }
}
var ImportModal = /* @__PURE__ */ function() {
  return __component__$2.exports;
}();
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("list-composer", {
    attrs: {
      "title": "Master File",
      "has-active": false,
      "has-filter-inactive": false
    },
    scopedSlots: _vm._u([{
      key: "customFilter",
      fn: function() {
        return [_c("select-input", {
          staticClass: "country-select-filter",
          attrs: {
            "reference": "identities.applications.contexts",
            "custom-query": "app=fas&entityLevel=country",
            "source": "entityLevelCode",
            "source-label": "entityLevelName",
            "default-active-first-option": ""
          },
          on: {
            "change": _vm.onCountryFilterChange
          }
        })];
      },
      proxy: true
    }, {
      key: "customButton",
      fn: function() {
        return [_vm.can(_vm.permissions.maintain) ? _c("a-button", {
          on: {
            "click": _vm.onClickImport
          }
        }, [_c("a-icon", {
          attrs: {
            "type": "import"
          }
        }), _vm._v(" Import ")], 1) : _vm._e()];
      },
      proxy: true
    }, {
      key: "action",
      fn: function(_ref) {
        var record = _ref.record;
        return [_vm.can(_vm.permissions.maintain) ? _c("a-tooltip", {
          scopedSlots: _vm._u([{
            key: "title",
            fn: function() {
              return [_vm._v("Download file")];
            },
            proxy: true
          }], null, true)
        }, [_c("a-button", {
          staticClass: "mr-1",
          attrs: {
            "icon": "download",
            "size": "small",
            "type": "link"
          },
          on: {
            "click": function($event) {
              return _vm.onDownload(record);
            }
          }
        })], 1) : _vm._e()];
      }
    }])
  }, [_c("text-field", {
    key: "FileName",
    attrs: {
      "data-index": "fileName",
      "title": "File Name"
    }
  }), _c("text-field", {
    key: "CountryCode",
    attrs: {
      "data-index": "countryCode",
      "title": "Country",
      "parse": _vm.parseCountry
    }
  }), _c("text-field", {
    key: "Year",
    attrs: {
      "data-index": "year",
      "title": "Year"
    }
  })], 1), _c("import-modal", {
    attrs: {
      "visible": _vm.importModalVisible
    },
    on: {
      "update:visible": function($event) {
        _vm.importModalVisible = $event;
      }
    }
  })], 1);
};
var staticRenderFns$1 = [];
var MasterFile_vue_vue_type_style_index_0_scoped_true_lang = "";
const __vue2_script$1 = {
  name: "MasterFile",
  components: {
    ImportModal
  },
  inject: ["crud", "apiUrl", "can"],
  data() {
    return {
      importModalVisible: false,
      permissions: {
        maintain: MAINTAIN_PERMISSION
      }
    };
  },
  methods: {
    parseCountry(value) {
      return value === "au" ? "AU" : "NZ";
    },
    onCountryFilterChange(country) {
      this.crud.setFilter("CountryCode", { operator: "Eq", value: country });
      this.crud.fetchList();
    },
    onClickImport() {
      this.importModalVisible = true;
    },
    async onDownload(record) {
      window.open(`${this.apiUrl}/${record.filePath}`, "_blank");
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, "409b64bd", null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var MasterFile = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("bread-crumb", {
    attrs: {
      "items": _vm.menuItems
    }
  }), _c("resource", {
    attrs: {
      "name": "identities.applications.contexts",
      "api-url": _vm.apiUrl
    }
  }), _c("resource", {
    attrs: {
      "name": "fas.filestorages",
      "api-url": _vm.apiUrl,
      "page": _vm.page
    }
  }, [_c("master-file")], 1)], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    MasterFile
  },
  data() {
    return {
      page: FAS_CONFIGURATION,
      apiUrl,
      menuItems: [
        {
          key: "masterfile",
          title: "Master File",
          path: ""
        }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
